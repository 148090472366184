import awsInstance, { methods } from '../../core/axios.config'
import { AxiosError, AxiosResponse, AxiosRequestConfig } from 'axios'
import { Dispatch } from 'redux'
import { User } from '.'

export const BEGIN_GET_USER_INFO = 'API/USER/BEGIN_GET_USER_INFO'
export const SUCCESS_GET_USER_INFO = 'API/USER/SUCCESS_GET_USER_INFO'
export const ERROR_GET_USER_INFO = 'API/USER/ERROR_GET_USER_INFO'

export const getUserInfoInitialState = {
    response: undefined,
    loading: false,
}

export interface GetUserInfoState {
    response: User | undefined
    status?: number
    statusText?: string
    loading: boolean
    error?: any
}

export interface GetUserInfoActions {
    type: string
    [item: string]: any
}

export const reducer = (newState: GetUserInfoState = getUserInfoInitialState, action: GetUserInfoActions) => {
    switch (action.type) {
        case BEGIN_GET_USER_INFO:
            return Object.assign({}, newState, { loading: true })

        case SUCCESS_GET_USER_INFO:
            const _data = { ...action.data }
            delete _data.result
            return Object.assign({}, newState, {
                response: { ...action.data.result, ..._data },
                loading: false,
                statusText: 'success',
                status: action.status
            })

        case ERROR_GET_USER_INFO:
            return Object.assign({}, newState, {
                statusText: 'error',
                loading: false,
                status: action.status
            })
        default:
            return newState
    }
}

export const beginGetUserInfo = () => ({
    type: BEGIN_GET_USER_INFO,
})

export const successGetUserInfo = (response: AxiosResponse) => ({
    type: SUCCESS_GET_USER_INFO,
    data: JSON.parse(response.data.message),
    status: response.status
})

export const errorGetUserInfo = (error: AxiosError) => ({
    type: ERROR_GET_USER_INFO,
    status: error.response?.status,
})

export const getUserInfo = (dispatch: Dispatch, id: string) => {
    const config: AxiosRequestConfig = {
        method: methods.GET,
        url: `/user/${id}`
    }
    dispatch(beginGetUserInfo())
    return awsInstance(config)
        .then((response: AxiosResponse) => {
            dispatch(successGetUserInfo(response))
            return response
        })
        .catch((error: AxiosError) => {
            dispatch(errorGetUserInfo(error))
            return new Promise<AxiosResponse>((resolve, reject) => { reject(error) })
        })

}

export default reducer