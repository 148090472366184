import React, { ChangeEventHandler, useEffect, useRef, useState } from 'react'

import { format, isValid, parse } from 'date-fns'
import FocusTrap from 'focus-trap-react'
import { ActiveModifiers, CaptionProps, DayPicker, useNavigation, DropdownProps } from 'react-day-picker'
import { usePopper } from 'react-popper'
import classNames from 'classnames'
import * as _  from 'lodash'

const CustomCaption = React.forwardRef((props: any, ref) => {
  const { goToMonth, nextMonth, previousMonth } = useNavigation()
  const scrollRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    document.documentElement.addEventListener('scroll', () => {
      console.log('wew')
    })
  })

  const onChangeYear = (value: any) => {
    props.setYear(value)
    if (props.month) {
      goToMonth(new Date(`${props.month}, ${value}`))
    }
  }

  const onChangeMonth = (value: any) => {
    props.setMonth(value)
    if (props.year) {
      goToMonth(new Date(`${value}, ${props.year}`))
    }
  }

  const _goToMonth = (month: Date) => {
    if (props.years?.includes(Number(format(month, 'yyy')))) {
      goToMonth(month)
      props.setMonth(String(format(month, 'MMM')))
      props.setYear(Number(format(month, 'yyy')))
      props.setOpenDatePopper(false)
    }
  }

  return (
    <>
      <div className='d-flex justify-content-center gap-5 am-pointer'>
        <button
          disabled={!previousMonth}
          onClick={() => previousMonth && _goToMonth(previousMonth)}
          className='border-0 px-2'
          type='button'
        >
          <img src='/Icons/left-arrow.png' loading='lazy' />
        </button>
        <h4 className='mt-2'>{format(props.displayMonth, 'MMM  yyy')}</h4>
        <button
          disabled={!nextMonth}
          onClick={() => nextMonth && _goToMonth(nextMonth)}
          className='border-0 px-2'
          type='button'
        >
          <img src='/Icons/right-arrow.png' loading='lazy' />
        </button>
      </div>
      {/* {props.isOpenDatePopper && ( */}
        <>
          <div
            tabIndex={-1}
            className="dialog-sheet am-calendar-picker am-month-picker"
            // style={{ bottom: props?.datePickerRef?.clientHeight <= 317 ? '-81%' : '-72%' }}
            // {...props.datePopper.attributes.popper}
            // ref={ref}
            role="dialog"
          >
            <div className="d-flex p-3 gap-4 justify-content-evenly">
              <span className={classNames({['date-active']: props.month === 'Jan'})} onClick={() => onChangeMonth('Jan')}>Jan</span>
              <span className={classNames({['date-active']: props.month === 'Feb'})} onClick={() => onChangeMonth('Feb')}>Feb</span>
              <span className={classNames({['date-active']: props.month === 'Mar'})} onClick={() => onChangeMonth('Mar')}>Mar</span>
            </div>
            <div className="d-flex p-3 gap-4 justify-content-evenly">
              <span className={classNames({['date-active']: props.month === 'Apr'})} onClick={() => onChangeMonth('Apr')}>Apr</span>
              <span className={classNames({['date-active']: props.month === 'May'})} onClick={() => onChangeMonth('May')}>May</span>
              <span className={classNames({['date-active']: props.month === 'Jun'})} onClick={() => onChangeMonth('Jun')}>Jun</span>
            </div>
            <div className="d-flex p-3 gap-4 justify-content-evenly">
              <span className={classNames({['date-active']: props.month === 'Jul'})} onClick={() => onChangeMonth('Jul')}>Jul</span>
              <span className={classNames({['date-active']: props.month === 'Aug'})} onClick={() => onChangeMonth('Aug')}>Aug</span>
              <span className={classNames({['date-active']: props.month === 'Sep'})} onClick={() => onChangeMonth('Sep')}>Sep</span>
            </div>
            <div className="d-flex p-3 gap-4 justify-content-evenly">
              <span className={classNames({['date-active']: props.month === 'Oct'})} onClick={() => onChangeMonth('Oct')}>Oct</span>
              <span className={classNames({['date-active']: props.month === 'Nov'})} onClick={() => onChangeMonth('Nov')}>Nov</span>
              <span className={classNames({['date-active']: props.month === 'Dec'})} onClick={() => onChangeMonth('Dec')}>Dec</span>
            </div>
          </div>
          <div
            tabIndex={-1}
            className="dialog-sheet am-calendar-picker am-year-picker"
            // style={{ bottom: props?.datePickerRef?.clientHeight <= 317 ? '-81%' : '-72%' }}
            {...props.datePopper.attributes.popper}
            ref={scrollRef}
            role="dialog"
          >
            <div className="d-flex flex-column gap-2 position-relative text-center">
              {props.years.map((_year: number) => (
                <>
                  <span id={String(_year)} className={classNames({['date-active']: props.year === _year}, 'am-pointer')} onClick={() => onChangeYear(_year)}>{_year}</span>
                </>
              ))}
              {/* <img src='Icons/sort-up.png' className='up'/>
              <img src='Icons/sort-down.png' className='down'/> */}
            </div>
          </div>
        </>
      {/* // )} */}
    </>
  )
})

export interface DatePickerProps {
  className?: string
  calendarClassName?: string
  onChange: (value: any) => any
  showCalendarIcon?: boolean
  startYear?: number
  advanceYear?: number
  disabled?: ((date: Date) => boolean) | Date | Date[] | { from: Date, to: Date } | { before: Date } | { after: Date } | { before: Date, after: Date } | { dayOfWeek: number[] }
  format?: string
  value?: string | Date | number
  defaultValue?: string
  placeholder?: string
  inputClassName?: string
  placement?: 'auto' | 'auto-start' | 'auto-end' | 'top' | 'bottom' | 'left' | 'right' | 'top-start' | 'bottom-start' | 'left-start' | 'right-start' | 'top-end' | 'bottom-end' | 'left-end' | 'right-end'
}

const DatePicker = (props: DatePickerProps) => {
  const [selected, setSelected] = useState<Date | undefined>(props.value ? new Date(props.value) : undefined)
  const [inputValue, setInputValue] = useState<string>(props?.value ? format(new Date(props.value), 'MM/dd/y') : '')
  const [isPopperOpen, setIsPopperOpen] = useState(false)
  const [year, setYear] = useState<number>(Number(format(props?.value ? new Date(props.value) : new Date(), 'yyy')))
  const [month, setMonth] = useState<string>(String(format(props?.value ? new Date(props.value) : new Date(), 'MMM')))
  const [height, setHeight] = useState()

  useEffect(() => {
    setSelected(props.value ? new Date(props.value) : undefined)
    setInputValue(props?.value ? format(new Date(props.value), 'MM/dd/y') : '')
    setYear(Number(format(props?.value ? new Date(props.value) : new Date(), 'yyy')))
    setMonth(String(format(props?.value ? new Date(props.value) : new Date(), 'MMM')))
  }, [props.value])

  const popperRef = useRef<HTMLDivElement>(null)
  const datePickerRef = useRef<HTMLDivElement>(null)
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  )

  const [datePopperElement, setDatePopperElement] = useState<HTMLDivElement | null>(
    null
  )

  const [isOpenDatePopper, setOpenDatePopper] = useState(false)

  const setDateRef = (ref: any) => {
    setPopperElement(ref)
    // datePickerRef = ref
    // if (ref?.clientHeight) {
    //   setHeight(ref.clientHeight)
    // }
    // setHeight(ref)
  }


  const popper = usePopper(popperRef.current, popperElement, {
    placement: props?.placement ?? 'bottom-start'
  })

  const _datePopper = usePopper(datePickerRef.current, datePopperElement, {
    placement: 'bottom-start'
  })

  const closePopper = (value?: string) => {
    setIsPopperOpen(false)
    if (value) {
      setYear(Number(format(new Date(value || inputValue), 'yyy')))
      setMonth(String(format(new Date(value || inputValue), 'MMM')))
    }
  }

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setInputValue(e.currentTarget.value)
    const date = parse(e.currentTarget.value, 'y-MM-dd', new Date())
    if (isValid(date)) {
      setSelected(date)
    } else {
      setSelected(undefined)
    }
  }

  const handleButtonClick = () => {
    setIsPopperOpen(true)
    if (inputValue) {
      setMonth(String(format(new Date(inputValue), 'MMM')))
      setYear(Number(format(new Date(inputValue), 'yyy')))
    }
  }

  const handleDaySelect = (date: Date | undefined, selectedDay: Date, activeModifiers: ActiveModifiers, e: React.MouseEvent) => {
    setSelected(date)
    if (date) {
      setInputValue(format(date, 'MM/dd/y'))
      closePopper(format(date, 'MM/dd/y'))
      props.onChange(format(date, props.format ?? 'MM/dd/y'))
    } else {
      setInputValue('')
    }
  }

  const onChangeYear = (value: any) => {
    setYear(value)
    // goToMonth(new Date(`${month}, ${value}`))
  }

  const onChangeMonth = (value: any) => {
    setMonth(value)
    // goToMonth(new Date(`${month}, ${month}`))
  }

  let _startYear = props?.startYear ?? 1970
  const _advanceYear = _.isNumber(props?.advanceYear) && props?.advanceYear >= 0 ? props.advanceYear : 10

  const _years: any = []
  while (_startYear <= new Date().getFullYear()) {
    _years.unshift(_startYear)
    _startYear++
  }

  for (let i = 1; i <= _advanceYear; i++) {
    _years.unshift(new Date().getFullYear() + i)
  }

  return (
    <div id='ArtDatePicker' className='am-datepicker'>
      <div ref={popperRef} className={classNames('calendar-container position-relative', props.className)}>
        <input
          type="text"
          placeholder={props?.placeholder ?? 'MM/dd/y'}
          value={inputValue}
          onClick={handleButtonClick}
          // onChange={handleInputChange}
          className={classNames('input-reset pa2 ma2 black ba am-pointer date-input rounded p-1 w-100', props?.inputClassName)}
        // disabled
        />
        {props?.showCalendarIcon && <img
          src='/Icons/calendar-icon.png'
          onClick={handleButtonClick}
          className={classNames('am-pointer am-calendar', props.calendarClassName)}
        />}
      </div>
      {isPopperOpen && (
        <FocusTrap
          active
          focusTrapOptions={{
            initialFocus: false,
            allowOutsideClick: false,
            clickOutsideDeactivates: true,
            onDeactivate: closePopper,
            // fallbackFocus: buttonRef.current
          }}
        >
          <div>
            <div
              tabIndex={-1}
              style={{...popper.styles.popper, height: '355px'}}
              className="dialog-sheet am-calendar-picker"
              {...popper.attributes.popper}
              ref={setPopperElement}
              role="dialog"
            >
              <div ref={datePickerRef}>
                <DayPicker
                  initialFocus={isPopperOpen}
                  mode="single"
                  defaultMonth={props?.defaultValue ? new Date(props?.defaultValue) : selected}
                  selected={selected}
                  onSelect={handleDaySelect}
                  disabled={props.disabled}
                  components={{
                    Caption: (captionProps: CaptionProps) => (<CustomCaption {...captionProps} datePopper={_datePopper} datePickerRef={datePickerRef} popperElement={popperElement} setPopperElement={setPopperElement} popper={popper} years={_years} isOpenDatePopper={isOpenDatePopper} setOpenDatePopper={setOpenDatePopper} year={year} month={month} setYear={setYear} setMonth={setMonth} />)
                  }}
                />
                </div>
            </div>
          </div>
        </FocusTrap>
      )}
    </div>
  )
}

export default DatePicker