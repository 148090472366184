import React from 'react'

import { compose, Dispatch } from 'redux'
import { connect } from 'react-redux'
import { ReduxAppState } from '../../utils/types'
import { withRouter } from '../../hoc/withRouter'
import { User } from '../../reducers/User'
import { FormattedMessage, FormattedNumber } from 'react-intl'
import { getUserInfo } from '../../reducers/User/getUserInfo'
import Sidebar from '../../hoc/sidebar'
import Portfolio from '../Portfolio/Portfolio'
import TranHistory from '../TranHistory/TranHistory'
import { UserTab } from '../../utils/routes'
import { getFunds, GetFundsState } from '../../reducers/Wallet/getFunds'
import { CLEAR_UPDATE_WALLET, updateWallet, UpdateWalletState } from '../../reducers/Wallet/updateWallet'
import { Wallet } from '../../reducers/Wallet'
import { CLEAR_UPDATE_USER_STATUS, updateUserStatus } from '../../reducers/User/updateUserStatus'
import { Alert, Modal, Spinner, Table } from 'react-bootstrap'
import Select from 'react-select'
import { userStatus } from '../../utils/userStatus'

export interface UserDetailsPageProps {
    router: any
}

export interface StateProps {
    userInfo?: User,
    wallet: Wallet,
    isLoading: boolean,
    isError: boolean,
    walletError: boolean,
    userStatusError: boolean
    updateWalletLoading: boolean
    updateUserStatusLoading: boolean
    updateWallet: UpdateWalletState
    getWalletLoading: GetFundsState
}

export interface DispatchProps {
    onGetUserInfo: (id: string) => any
    onGetFunds: (id: string) => any
    onAddFunds: (id: string, data: any) => any
    onUpdateUserStatus: (data: any) => any
    clearUpdateWallet: () => any
    clearUpdateUserStatus: () => any
}

export interface HomePageState {
    selectedTooltip: string
    selectedTab: string
    selectedOption: number
    tempSelectedOption: number
    newBalance: string
    isOpenModal: boolean
    isConfirmModal: boolean
    isSubmit: boolean
}

export type UserDetailsProps = UserDetailsPageProps & StateProps & DispatchProps

const UserInfo = (props: any) => {
    return (
        <div className='flex-user-container mx-5'>
            <label className='flex-user-child'><span style={{ fontSize: 25, verticalAlign: 'middle', marginRight: 10 }}>{`\u2022`}</span><span>{props.label}</span></label>
            <p className='flex-user-child'>{props.value}</p>
            <span className='flex-user-child'>{props.children}</span>
        </div>
    )
}

const selectStyles = {
    control: (base: any) => ({
        ...base,
        fontSize: 14,
        fontWeight: 900,
        width: 180
    }),
    menu: (base: any) => ({
        ...base,
        fontSize: 14,
        fontWeight: 900
    })
};

const options = [
    { value: 0, label: <div className='d-flex flex-row'><span style={{ color: '#29B34B', marginRight: '0.5em' }}>&#9899;&#xfe0e;</span>ACTIVE</div> },
    { value: 1, label: <div className='d-flex flex-row'><span style={{ color: '#DB0000', marginRight: '0.5em' }}>&#9899;&#xfe0e;</span>BLOCKED</div> },
    { value: 2, label: <div className='d-flex flex-row'><span style={{ color: '#3B3B3B', marginRight: '0.5em' }}>&#9899;&#xfe0e;</span>DORMANT</div> },
]

class UserDetailsPage extends React.Component<UserDetailsProps, HomePageState> {
    constructor(props: UserDetailsProps) {
        super(props)
        this.state = {
            tempSelectedOption: 0,
            selectedOption: 0,
            selectedTab: UserTab.PORTFOLIO,
            selectedTooltip: '',
            newBalance: '',
            isOpenModal: false,
            isConfirmModal: false,
            isSubmit: false
        }
    }

    componentDidMount(): void {
        const userId = this.props.router?.params?.id
        this.props.onGetUserInfo(userId).then(() => {
            this.setState({
                selectedOption: this.props?.userInfo?.status || 0,
                tempSelectedOption: this.props?.userInfo?.status || 0
            })
        })
        this.props.onGetFunds(userId)
    }

    componentWillUnmount() {
        this.props.clearUpdateWallet()
    }

    onGetAddress = () => {
        const street = this.props.userInfo?.hasOwnProperty('street') ? this.props.userInfo?.street : ''
        const barangay = this.props.userInfo?.hasOwnProperty('barangay') ? this.props.userInfo?.barangay : ''
        const cityMunicipality = this.props.userInfo?.hasOwnProperty('cityMunicipality') ? this.props.userInfo?.cityMunicipality : ''
        return `${street} ${barangay} ${cityMunicipality}`
    }

    onGetShares(numberOfShare: number, totalShare: number) {
        return numberOfShare / totalShare
    }

    onSelectToolTip = (userId: string) => {
        this.setState({
            selectedTooltip: userId
        })
    }

    onSelectTab = (tabName: string) => {
        this.setState({
            selectedTab: tabName
        })
    }

    onHandleSelect = (selectedOption: any) => {
        const value = selectedOption?.value
        if (this.state.selectedOption === value) {
            return
        }

        this.setState({
            isConfirmModal: true,
            tempSelectedOption: value
        })
    }

    onCloseConfirmModal = () => {
        this.setState({
            isConfirmModal: false,
            tempSelectedOption: this.state.selectedOption
        })
    }

    onAddFund = () => {
        this.setState({ isSubmit: true })
        if (this.state.newBalance !== '') {
            this.props.onAddFunds(
                this.props.wallet.id,
                {
                    runningBalance: Number(this.state.newBalance),
                    balance: Number(this.state.newBalance),
                    amt: Number(this.state.newBalance),
                    userId: this.props.userInfo?.userId,
                    email: this.props.userInfo?.email,
                    tranType: 'Deposit',
                    isUpdate: true
                })
                .then(async () => {
                    await this.props.onGetFunds(this.props.router?.params?.id)
                    this.setState({ isSubmit: false, isOpenModal: false, newBalance: '' })
                    setTimeout(this.props.clearUpdateWallet, 5000)
                })
                .catch(() => {
                    // error
                })
        }
    }

    onToggleModal = (isOpen: boolean) => {
        this.setState({ isOpenModal: isOpen, newBalance: '', isSubmit: false })
    }

    onChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value
        const pattern = /^[0-9\b]+$/
        if (newValue === '' || pattern.test(newValue)) {
            this.setState({ newBalance: newValue })
        }
    }

    onGetBalanceWithDecimal = (num: number) => {
        const balance = num.toString()
        const amount = parseFloat(balance).toFixed(2);
        return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }

    onChangeUserStatus = (status: number) => {
        const userId = this.props.router?.params?.id
        const data = {
            'email': this.props.userInfo?.email,
            'userId': userId,
            'action': status
        }

        this.props.onUpdateUserStatus(data)
            .then(async () => {
                await this.props.onGetUserInfo(userId)
                setTimeout(this.props.clearUpdateWallet, 5000)
                this.setState({
                    selectedOption: this.state.tempSelectedOption,
                    isConfirmModal: false
                })
            })
            .catch(() => {
                // error
            })
    }

    onGetStatusDesc = (status: number) => {
        const selectedStatus = userStatus.filter(({ value }) => value === status)
        return selectedStatus[0].label
    }

    render() {
        const { userInfo, isError, wallet } = this.props
        return (
            <React.Fragment>
                <Modal show={this.state.isConfirmModal}>
                    <div className='w-100 text-center p-5'>
                        <div><img loading='lazy' src='/Icons/delete-icon-large.png' width={50} height={50} className="delete-icon mt-1" /></div>
                        <p className='my-2'><FormattedMessage id='UserDetails.warning.changeStatus' values={{ value: this.onGetStatusDesc(this.state.tempSelectedOption) }} /></p>
                        <div className="d-flex mx-5 gap-5">
                            <button disabled={this.props.updateUserStatusLoading} onClick={() => this.onChangeUserStatus(this.state.tempSelectedOption)} className="btn w-50" style={{ background: '#F68C29' }}>{this.props.updateUserStatusLoading ? <Spinner animation='border' size='sm' /> : 'Yes'}</button>
                            <button disabled={this.props.updateUserStatusLoading} onClick={this.onCloseConfirmModal} className="btn btn-secondary w-50">No</button>
                        </div>
                    </div>
                </Modal>
                <div className="header"><img loading='lazy' src='/Icons/am-logo3.png' /></div>
                <div className='d-flex flex-column mb-2 mt-3 mx-5'>
                    <button onClick={() => this.props.router.navigate(-1)} className='back-btn d-flex justify-content-evenly mb-4'><img loading='lazy' src='/Icons/arrow-icon.png' width={15} height={15} className="mt-1 mx-1" /><FormattedMessage id='UserDetails.button.back' /></button>
                </div>
                <div className='d-flex justify-content-between align-items-center mb-3 mx-5'>
                    <p className='title'><FormattedMessage id='UserDetails.label.accountListing' /></p>
                    <div className='d-flex flex-row justify-content-between'>
                        <div className='d-flex flex-row'>
                            <p className='my-2 mx-3' style={{ fontWeight: 900 }}><FormattedMessage id='UserDetails.label.status' /></p>
                            <Select
                                placeholder={'Status'}
                                value={options.filter(({ value }) => value === this.state.selectedOption)}
                                onChange={this.onHandleSelect}
                                options={options}
                                styles={selectStyles}
                            />
                        </div>
                    </div>
                </div>
                {isError && <div className="d-flex flex-row mx-5"><p><FormattedMessage id='UserDetails.msg.somethingWentWrong' /></p></div>}
                <div className='user-info mx-5'>
                    <UserInfo label={'Name:'} value={userInfo?.name} />
                    <UserInfo label={'Address:'} value={this.onGetAddress()} />
                    <UserInfo label={'Email:'} value={userInfo?.email} children={<p className='user-label'><FormattedMessage id='UserDetails.label.updateEmail' /></p>} />
                    <UserInfo label={'Running Balance:'} value={<FormattedNumber format={userInfo?.ccy} value={Number(wallet?.balance ?? 0)} />} children={<p className='user-label' onClick={() => this.onToggleModal(true)}><FormattedMessage id='UserDetails.label.editBalance' /></p>} />
                </div>
                <div className='d-flex justify-content-between align-items-center mb-3 mt-4 mx-5'>
                    <p className='title'>{this.state.selectedTab}</p>
                    <div className='d-flex flex-row justify-content-between'>
                        <button className={this.state.selectedTab === UserTab.ACCOUNT_HISTORY ? 'active-user-details-btn' : 'user-details-btn'} onClick={() => this.onSelectTab(UserTab.ACCOUNT_HISTORY)} color={this.state.selectedTab === UserTab.ACCOUNT_HISTORY ? '#BDBDBD' : ''}><FormattedMessage id='UserDetails.label.accountHistory' /></button>
                        <button className={this.state.selectedTab === UserTab.TRAN_HISTORY ? 'active-user-details-btn' : 'user-details-btn'} onClick={() => this.onSelectTab(UserTab.TRAN_HISTORY)} color={this.state.selectedTab === UserTab.TRAN_HISTORY ? '#BDBDBD' : ''}><FormattedMessage id='UserDetails.label.transaction' /></button>
                        <button className={this.state.selectedTab === UserTab.PORTFOLIO ? 'active-user-details-btn' : 'user-details-btn'} onClick={() => this.onSelectTab(UserTab.PORTFOLIO)} color='red'><FormattedMessage id='UserDetails.label.portfolio' /></button>
                    </div>
                </div>
                {this.state.selectedTab === UserTab.ACCOUNT_HISTORY ? null : this.state.selectedTab === UserTab.TRAN_HISTORY ? <TranHistory /> : <Portfolio />}

                {/* Wallet Modal*/}
                <Modal
                    show={this.state.isOpenModal}
                    dialogClassName={'custom-dialog'}
                    contentClassName={'backdrop-classname'}
                >
                    {this.props.updateWallet?.statusText === 'error' && (
                        <div className='mx-5 mb-3'>
                            <p style={{ textAlign: 'center', padding: '10px', background: 'red', color: '#FFF', fontSize: '14px' }}><FormattedMessage id='UserDetails.msg.somethingWentWrong' /></p>
                        </div>
                    )}
                    <div style={{ width: 480, alignItems: 'center', background: '#E5E4E2', borderRadius: 10 }}>
                        <div className='input-container mx-3'>
                            <h6 style={{ marginTop: 20, marginBottom: 20, textTransform: 'uppercase', fontWeight: 'bold' }}><FormattedMessage id='UserDetails.label.currentBalance' /></h6>
                            <div>
                                <span style={{ position: 'absolute', marginLeft: 15, marginTop: 8 }}>$</span>
                                <input className='balance-text' style={{ background: '#D9D9D9' }} type="text" value={this.onGetBalanceWithDecimal(wallet?.balance ?? 0)} step='0.01' disabled />
                            </div>
                        </div>
                        <div className='input-container mx-3 mt-6'>
                            <h6 style={{ marginTop: 20, marginBottom: 20, textTransform: 'uppercase', fontWeight: 'bold' }}><FormattedMessage id='UserDetails.label.newBalance' /></h6>
                            <input
                                className={this.state.isSubmit && !this.state.newBalance ? 'input-text error-border' : 'input-text'}
                                type="number"
                                onChange={e => this.onChangeValue(e)}
                                placeholder='(New Balance)'
                            />
                            {this.state.isSubmit && !this.state.newBalance && <p className='error-text'><FormattedMessage id='UserDetails.error.newBalance' /></p>}
                        </div>
                        <div className='d-flex justify-content-end mb-3 mt-4 mx-2'>
                            <button onClick={() => this.onAddFund()} className='modal-btn' type="submit" style={{ background: '#F0AF73', color: 'black', fontWeight: 800 }} disabled={this.props.updateWalletLoading}>{this.props.updateWalletLoading && <i className="fa fa-spinner fa-spin"></i>}<FormattedMessage id='UserDetails.button.confirm' /></button>
                            <button onClick={() => this.onToggleModal(false)} className='modal-btn' type="submit" style={{ background: '#BDBDBD', color: 'black', fontWeight: 800 }} disabled={this.props.updateWalletLoading}><FormattedMessage id='UserDetails.button.cancel' /></button>
                        </div>
                    </div>
                </Modal>
            </React.Fragment >
        )
    }
}

const mapStateToProps = (state: ReduxAppState) => {
    const _getUserInfo = state.api?.user?.getUserInfo
    return {
        userInfo: _getUserInfo?.response,
        isLoading: _getUserInfo?.loading,
        isError: _getUserInfo.statusText === 'error',
        wallet: state.api.wallet.getFunds.response,
        walletError: state.api.wallet?.getFunds?.statusText === 'error',
        getWalletLoading: state.api.wallet?.getFunds.loading,
        updateWalletLoading: state.api.wallet?.updateWallet?.loading,
        updateWallet: state.api.wallet?.updateWallet,
        updateUserStatusLoading: state.api.user?.updateUserStatus?.loading,
        updateUserStatusError: state.api.user?.updateUserStatus?.statusText === 'error'
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    onGetUserInfo: ((id: string) => getUserInfo(dispatch, id)),
    onGetFunds: (id: string) => getFunds(dispatch, id),
    onAddFunds: (id: string, data: any) => updateWallet(dispatch, id, data),
    onUpdateUserStatus: (data: any) => updateUserStatus(dispatch, data),
    clearUpdateWallet: () => dispatch({ type: CLEAR_UPDATE_WALLET }),
    clearUpdateUserStatus: () => dispatch({ type: CLEAR_UPDATE_USER_STATUS })
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(compose(Sidebar)(UserDetailsPage as any)))
