import React from 'react'
import { IntlProvider as MessageProvider, FormattedMessage } from 'react-intl'
import { persistReducer, persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'
import sessionStorage from 'redux-persist/lib/storage/session'
import { applyMiddleware, combineReducers, createStore, compose } from 'redux'
import { Provider as StoreProvider } from 'react-redux'
import thunkMiddleware from 'redux-thunk'

import ApiReducer, { apiInitialState } from './reducers/api'
import AppRoutes from './routes/AppRoutes'
import amplify from '@aws-amplify/core'
import config from './config'
import formats from './utils/formats'
import Modal from 'react-modal'
import 'react-day-picker/dist/style.css'

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    registerables
  } from 'chart.js'

ChartJS.register(
CategoryScale,
LinearScale,
PointElement,
LineElement,
Title,
Tooltip,
Legend,
...registerables
)

const messages = require('../dist/en-us.json')
const staticConfig = require('../static/default.json')

Modal.setAppElement('#root');

amplify.configure({
    Auth: {
        mandatorySignIn: true,
        region: config.cognito.REGION,
        userPoolId: config.cognito.USER_POOL_ID,
        identityPoolId: config.cognito.IDENTITY_POOL_ID,
        userPoolWebClientId: config.cognito.APP_CLIENT_ID,
        oauth: {
            domain: config.oauth.DOMAIN,
            scope: config.oauth.SCOPE,
            redirectSignIn: config.oauth.REDIRECT_SIGNIN,
            redirectSignOut: config.oauth.REDIRECT_SIGNOUT,
            responseType: config.oauth.RESPONSE_TYPE
        }
    },
    API: {
        endpoints: [
            {
                name: 'art-api',
                endpoint: config.apiGateway.URL,
                region: config.apiGateway.REGION
            }
        ]
    }
})

const App = () => {
    const reducer = combineReducers({
        api: ApiReducer,
        config: () => Object.assign({}, staticConfig)
    })
    const persistConfig = {
        key: 'root',
        storage: sessionStorage
    }
    const persistedReducer = persistReducer(persistConfig, reducer)
    const store = createStore(persistedReducer, {
        api: apiInitialState
    }, compose(applyMiddleware(thunkMiddleware)))
    const persistor = persistStore(store)
    return (
        <MessageProvider formats={formats} messages={messages} locale="en" defaultLocale='en'>
            <StoreProvider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <AppRoutes />
                </PersistGate>
            </StoreProvider>
        </MessageProvider>
    )
}

export default App