import React, { useRef, useState, useCallback } from 'react'
import { Alert, Button, Modal, Spinner } from 'react-bootstrap'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import * as yup from 'yup'
import { withRouter } from '../../hoc/withRouter'
import { Items } from '../../reducers/Items'
import { RESET_UPDATE_LISTING, updateListing } from '../../reducers/Items/updateListing'
import { ArtDatePicker, ArtForm } from '../../ReusableComponent'
import ArtField, { ArtFieldsType } from '../../ReusableComponent/Form/ArtFields'
import { ReduxAppState } from '../../utils/types'
import classNames from 'classnames'
import { AxiosPromise } from 'axios'
import moment from 'moment'
import { rest } from 'lodash'

const FormSchema = yup.object({
    amountSold: yup.string().required('Amount Sold is required'),
    dateOfDisbursement: yup.string().required('Date of disbursement is required'),
    proofOfSale: yup.mixed().notOneOf(['', false, undefined, null], 'Proof of sale is required'),
})

export interface OwnProps {
    item: Items
    isOpen: boolean
}

export interface DispatchProps {
    onUpdateListing: (itemId: string, data: any) => AxiosPromise
    setItemToUpdate: (item?: any) => void
    onClearUpdateListing: () => void
    onRefreshItem: () => void
}

export interface StateProps {
    isLoading: boolean
    isError: boolean
}

export type UpdateListingProps = OwnProps & DispatchProps & StateProps


const UpdateListingStatusModal = (props: UpdateListingProps) => {
    const [proofOfSale, setProofOfSale] = useState('')
    const [soldAmount, setSoldAmount] = useState(0)
    const handleFile = (file: any, setFile: any) => {
        const reader = new FileReader()
        reader.onload = (e: any) => {
            setProofOfSale(file.name)
            setFile({
                fileName: file.name,
                decodedFile: e?.target?.result?.split(',')[1],
                type: file.type
            })
        }
        reader.readAsDataURL(file)
    }

    const _onSubmit = (data: any) => {
        props.onUpdateListing(props.item?.data?.id, {...data, isPhysicallySold: true, amountSold: Number(data.amountSold), dateOfDisbursement: Date.parse(new Date(String(data.dateOfDisbursement)).toString()), dateSold: Date.parse(new Date().toString()), isDisbursed: false})
        .then(() => {
            props.setItemToUpdate(undefined)
            reset()
            props.onRefreshItem()
            setProofOfSale('')
        })
        .finally(() => {
            setTimeout(props.onClearUpdateListing, 10000)
        })
    }

    const onClose = () => {
        props.setItemToUpdate(undefined)
        reset()
    }

    const _proofOfSale: any = useRef()
    const isDisabled = Number(soldAmount) < Number(props.item?.data?.listedValue)
    const { Form, onSubmit, control, isDirty, reset } = ArtForm({ formSchema: FormSchema, onSubmit: _onSubmit, otherOptions: { mode: 'onSubmit', criteriaMode: 'all' } })
    return props.isOpen && (
        <Modal show contentClassName='updateListing__modalContent' size='lg' fullscreen='xl-down'>
            <Form className='p-5 d-flex flex-column gap-2' onSubmit={onSubmit}>
                {props.isError && <Alert variant='danger'>Something went wrong. Please Try again</Alert>}
                <h3 className='mb-5'>UPDATE LISTING STATUS</h3>
                <label><strong>Amount Sold</strong></label>
                <ArtField onChange={setSoldAmount} className='rounded w-100 bg-white' id='amountSold' defaultValue={''} name='amountSold' type='number' fieldstype={ArtFieldsType.ArtInput} control={control} />
                {isDisabled && isDirty && <span style={{ color: 'red' }}>Amount should be higher than listing price.</span>}
                <label className='mt-4'><strong>Proof of sale</strong></label>
                {/* <input className='rounded w-100 mb-4 bg-white am-pointer p-1 art-input' id='amountSold' placeholder='Attachement' defaultValue={'ATTACHMENT'} name='amountSold' type='text'  /> */}
                <ArtField control={control} id='proofOfSale' name='proofOfSale' fieldstype={ArtFieldsType.CustomField} renderField={(_onChange: any, value: any, error?: any) => (
                    <div className='mb-4'>
                        <button className={classNames('btn bg-white w-100 p-3', { ['border-danger']: error })} style={{ textAlign: 'left' }} onClick={(e) => {
                            e.preventDefault()
                            _proofOfSale?.current?.click()}
                            }>Attachment {proofOfSale && `(${proofOfSale})`}</button>
                        <input type='file' multiple className='d-none' ref={_proofOfSale} onChange={e => handleFile(e?.target?.files![0], _onChange)} />
                    </div>
                 )}/>
                <label><strong>Date of Disbursemen  t</strong></label>
                <ArtField id='dateOfDisbursement' name='dateOfDisbursement' defaultValue={''} fieldstype={ArtFieldsType.CustomField} control={control} renderField={(onChange: any, value: any, error?: any) => (
                    <ArtDatePicker onChange={onChange} placement='top-start' advanceYear={5} placeholder='MM/DD/YYYY' className='rounded' inputClassName={classNames('updateListing__datepicker bg-white', { ['border border-danger']: error })} />
                )} />
                <div className="d-flex flex-row justify-content-end mt-5 gap-3">
                    <Button disabled={isDisabled} className='btn border-0' type='submit' style={{ backgroundColor: '#F68C2999', width: '131px', height: '43px', color: 'black' }} onClick={onSubmit}>{props.isLoading ? <Spinner size='sm' animation='border' /> : <strong>Yes</strong>}</Button>
                    <Button className='btn border-0' style={{ backgroundColor: '#BDBDBD', width: '131px', height: '43px', color: 'black' }} onClick={onClose}><strong>Cancel</strong></Button>
                </div>
            </Form>
        </Modal>
    )
}

const mapStateToProps = (state: ReduxAppState) => {
    return {
        isLoading: state.api.items?.updateListing?.loading,
        isError: state.api.items?.updateListing?.statusText === 'error'
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    onUpdateListing: (itemId: string, data: any) => updateListing(dispatch, data, itemId),
    onClearUpdateListing: () => dispatch({ type: RESET_UPDATE_LISTING })
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UpdateListingStatusModal as any))