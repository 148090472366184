import React from 'react'
import { OverlayTrigger } from 'react-bootstrap'

const ArtTooltip = (props: any) => {
  return (
    <OverlayTrigger
      placement="right"
      delay={{ show: 250, hide: 400 }}
      overlay={props.renderTooltip()}
      {...props}
    >
      {props.children}
    </OverlayTrigger>
  );
}

export default ArtTooltip;