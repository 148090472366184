// const config = require('config')
// const url = config.get('environment.websocketURL');

export default class Socket {
    static ws

    static init(data) {
        this.ws = new WebSocket('wss://79se1a9ih8.execute-api.ap-southeast-1.amazonaws.com/uat')
        this.ws.addEventListener('open', function (event) {
            Socket.sendMessage(JSON.stringify({
                action: 'updateConnection',
                userId: data
            }))
        })
    }

    static onMessage(handler, userId) {
        if (this.ws !== undefined) {
            this.ws.addEventListener('message', handler)
        } else {
            this.init(userId)
        }
    }

    static sendMessage(message, userId) {
        if (this.ws !== undefined) {
            this.ws.send(message)
        } else {
            this.init(userId)
        }
    }

    static closeConnection() {
        if (this.ws !== undefined) {
            this.ws.close()
        }
    }
}