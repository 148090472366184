import React from 'react'
import { connect } from 'react-redux'
import { compose, Dispatch } from 'redux'
import SidebarHOC from '../../hoc/sidebar'
import { withRouter } from '../../hoc/withRouter'
import { ArtButton, ArtDatePicker, ArtDropdown, ArtHeader } from '../../ReusableComponent'
import { ReduxAppState } from '../../utils/types'

import { Bar } from 'react-chartjs-2'
import moment from 'moment'
import { getAnalytics } from '../../reducers/Analytics/getAnalytics'
import { Analytics as AnalyticsData } from '../../reducers/Analytics'
import { format } from 'date-fns'
import * as _ from 'lodash'
import { Navigate, RouterProps } from 'react-router'
import { Spinner } from 'react-bootstrap'
import { FormattedMessage, injectIntl } from 'react-intl'

export interface AnalyticsOwnProps {
    router: RouterProps
    intl: any
}

export interface StateProps {
    analytics: AnalyticsData[]
    isAnalyticsLoading: boolean
}

export interface DispatchProps {
    onGetAnalytics: (query: any) => any
}

export type AnalyticsProps = DispatchProps & StateProps & AnalyticsOwnProps

export interface AnalyticsState {
    selectedValue: string
    fromDate: number | string | Date
    toDate: number | string | Date
}

class Analytics extends React.Component<AnalyticsProps, AnalyticsState> {
    constructor(props: any) {
        super(props)
        this.state = {
            selectedValue: '1',
            fromDate: moment(moment(new Date()).startOf('month')).valueOf(),
            toDate: moment(moment(new Date()).endOf('month')).valueOf()
        }
    }

    componentDidMount(): void {
        this.onApplyFilter()
    }

    componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any): void {
        const _itemId = _.get(this.props, 'router.params.itemId')
        const _prevItemId = _.get(prevProps, 'router.params.itemId')
        if (_itemId !== _prevItemId) {
            this.onApplyFilter()
        }
    }

    onChangeDate = (key: string, value: string) => {
        let filter: 'endOf' | 'startOf' = 'endOf'
        if (key === 'fromDate') {
            filter = 'startOf'
            this.setState({
                fromDate: moment(value).startOf('day').valueOf(),
                toDate: ''
            })
        } else {
            this.setState({
                toDate: moment(value).endOf('day').valueOf()
            })
        }
    }

    onChangeFilter = (value: string) => {
        if (value === '1') {
            this.setState({
                selectedValue: value,
                fromDate: moment(moment(new Date()).startOf('month')).valueOf(),
                toDate: moment(moment(new Date()).endOf('month')).valueOf()
            })
        } else if (value === '30' || value === '60' || value === '90') {
            this.setState({
                selectedValue: value,
                fromDate: moment().subtract(Number(value), 'days').valueOf(),
                toDate: moment().valueOf()
            })
        } else {
            this.setState({
                selectedValue: value,
                fromDate: '',
                toDate: ''
            })
        }
    }

    onApplyFilter = () => {
        const _itemId = _.get(this.props, 'router.params.itemId')
        if (_itemId) {
            this.props.onGetAnalytics({ field: 'itemId', value: _itemId, startDate: moment(this.state.fromDate).startOf('day').valueOf(), endDate: moment(this.state.toDate).endOf('day').valueOf() })
        }
    }

    render() {
        const options = {
            responsive: true,
            plugins: {
                legend: {
                    display: false
                }
            },
            offset: true,
            elements: {
                bar: {
                    // inflateAmount: 5
                }
            }
        }
        const labels = this.props?.analytics?.length > 0 ? this.props?.analytics.map((item: AnalyticsData) => format(new Date(item.createdAt), 'MM/dd/y')) : []
        const data = {
            labels,
            datasets: [
                {
                    label: this.props.intl.formatMessage({ id: 'Analytics.label.sold' }),
                    data: this.props?.analytics?.length > 0 ? this.props?.analytics.map((item: AnalyticsData) => item.noOfSoldItems) : [],
                    backgroundColor: '#F68C29',
                    inflateAmount: 5,
                },
                {
                    label: this.props.intl.formatMessage({ id: 'Analytics.label.remainingShares' }),
                    data: this.props?.analytics?.length > 0 ? this.props?.analytics.map((item: AnalyticsData) => item.noOfRemainingShares) : [],
                    backgroundColor: '#0F1922',
                    inflateAmount: 10,
                },
            ],
        }
        const _ddlList = [{
            label: 'This Month',
            value: '1'
        }, {
            label: 'Last 30 Days',
            value: '30'
        }, {
            label: 'Last 60 Days',
            value: '60'
        }, {
            label: 'Last 90 Days',
            value: '90'
        }, {
            label: 'Custom',
            value: 'Custom'
        }]

        const _itemId = _.get(this.props, 'router.params.itemId')
        return (
            <div id='AnalyticsComponent-1' className='AnalyticsComponent'>
                {!_itemId && <Navigate to='/listing' />}
                <ArtHeader />
                <div className="d-flex justify-content-between mx-5 mt-5">
                    <h1 className='title'><FormattedMessage id='Analytics.label.analytics' /></h1>
                    <div className='d-flex flex-row justify-content-between'>
                        <ArtButton style={{ background: '#BDBDBD', color: 'black', fontWeight: 800 }} className='button rounded'><FormattedMessage id='Analytics.label.analytics' /></ArtButton>
                        <ArtButton className='button'><FormattedMessage id='Analytics.label.artOwners' /></ArtButton>
                    </div>
                </div>
                <div className="d-flex justify-content-between tiles mx-4 mt-3 rounded">
                    <div className="d-flex gap-5 mt-1">
                        <h2><FormattedMessage id='Analytics.label.timePeriod' /></h2>
                        <ArtDropdown value={this.state.selectedValue} items={_ddlList} onClick={this.onChangeFilter} className='analyticsDateFilter rounded mt-1' />
                        {/* <DayPicker
                            mode="single"
                            footer={'footer'}
                        /> */}
                        {this.state.selectedValue === 'Custom' && <div className="d-flex gap-3">
                            <ArtDatePicker value={this.state.fromDate} className='text-center' onChange={(value: string) => this.onChangeDate('fromDate', value)} advanceYear={5} placeholder='MM/DD/YYYY' inputClassName='datepicker-input' />
                            <span className='am-text-bold'>_</span>
                            <ArtDatePicker value={this.state.toDate} className='text-center' onChange={(value: string) => this.onChangeDate('toDate', value)} advanceYear={5} placeholder='MM/DD/YYYY' inputClassName='datepicker-input' disabled={{ before: new Date(this.state.fromDate) }} />
                        </div>}
                    </div>
                    <div>
                        <ArtButton onClick={this.onApplyFilter} className='applyButton' disabled={!this.state.fromDate || !this.state.toDate}><FormattedMessage id='Analytics.button.apply' /></ArtButton>
                    </div>
                </div>
                <div className="div w-100 h-50 d-flex justify-content-center mt-3">
                    {this.props.isAnalyticsLoading ? <div><Spinner animation='border' size='sm' style={{ width: '3rem', height: '3rem' }} /></div> :
                        this.props?.analytics?.length > 0 ? (
                            <div className='bar-container'>
                                <Bar className='mx-5' options={options} data={data} />
                                <div className="d-flex justify-content-end gap-3 mr-5 my-5 px-5">
                                    <div className='d-flex gap-1'>
                                        <div className='py-2 px-3 rounded border am-bg-orange' />
                                        <span><FormattedMessage id='Analytics.label.sold' /></span>
                                    </div>
                                    <div className='d-flex gap-1'>
                                        <div className='py-2 px-3 rounded border bg-black' />
                                        <span><FormattedMessage id='Analytics.label.remainingShares' /></span>
                                    </div>
                                </div>
                            </div>
                        ) : <div><FormattedMessage id='Analytics.label.noDataFound' /></div>
                    }
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state: ReduxAppState) => {
    return {
        analytics: state.api?.analytics?.getAnalytics?.response,
        isAnalyticsLoading: state.api?.analytics?.getAnalytics?.loading
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    onGetAnalytics: (query: { field?: string, value?: string, fromDate?: number, toDate?: number }) => getAnalytics(dispatch, query)
})

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(compose(SidebarHOC)(Analytics as any))))