import React, { ReactNode } from 'react'
import classNames from 'classnames'

export interface ButtonProps {
    className?: string
    children: ReactNode
    onClick: any
    disabled?: boolean
    style?: any
}

const Button = (props: ButtonProps) => {
    return (
        <button {...props} className={classNames('artButton', { ['am-disabled-cursor']: props.disabled }, props.className)} disabled={props.disabled} onClick={props.onClick}>{props.children}</button>
    )
}

export default Button