import React, { useRef, useState, useContext } from 'react'
import { UseFormInstanceMeta } from 'react-form'
import { connect } from 'react-redux'
import { compose, Dispatch } from 'redux'
import SidebarHOC from '../../hoc/sidebar'
import { withRouter } from '../../hoc/withRouter'
import { ArtDatePicker, ArtForm, ArtHeader, ArtInput } from '../../ReusableComponent'
import ArtField, { ArtFieldsType } from '../../ReusableComponent/Form/ArtFields'
import { ReduxAppState } from '../../utils/types'
import * as yup from 'yup'
import classnames from 'classnames'
import { addItem, RESET_ADD_ITEM } from '../../reducers/Items/addItem'
import { AnalyticsS3ExportFileFormat } from '@aws-sdk/client-s3'
import { Alert, Spinner, Modal } from 'react-bootstrap'
import { Navigate } from 'react-router-dom'
import _ from 'lodash'
import { FormattedMessage } from 'react-intl'

const FormSchema = yup.object({
    title: yup.string().required('Artwork is required'),
    artist: yup.string().required('Artist is required'),
    listedValue: yup.string().required('Value is required'),
    year: yup.string().required('Year is required'),
    dmt: yup.string().required('Dimensions, Material and Technique is required'),
    // shareOnSale: yup.string().required('Share on sale is required'),
    listingDate: yup.string()
        .required('Listing date is required'),
    // .matches(/^(?:(?:(?:0?[13578]|1[02]|(?:Jan|Mar|May|Jul|Aug|Oct|Dec))(\/|-|\.)31)\1|(?:(?:0?[1,3-9]|1[0-2]|(?:Jan|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec))(\/|-|\.)(?:29|30)\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:(?:0?2|(?:Feb))(\/|-|\.)(?:29)\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:(?:0?[1-9]|(?:Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep))|(?:1[0-2]|(?:Oct|Nov|Dec)))(\/|-|\.)(?:0?[1-9]|1\d|2[0-8])\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/, 'Not a valid date (mm/dd/yyyy)'),
    saleDate: yup.string()
        .required('Sale Date is required'),
    // .matches(/^(?:(?:(?:0?[13578]|1[02]|(?:Jan|Mar|May|Jul|Aug|Oct|Dec))(\/|-|\.)31)\1|(?:(?:0?[1,3-9]|1[0-2]|(?:Jan|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec))(\/|-|\.)(?:29|30)\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:(?:0?2|(?:Feb))(\/|-|\.)(?:29)\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:(?:0?[1-9]|(?:Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep))|(?:1[0-2]|(?:Oct|Nov|Dec)))(\/|-|\.)(?:0?[1-9]|1\d|2[0-8])\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/, 'Not a valid date (mm/dd/yyyy)'),
    displayLocation: yup.string().required('Display location is required'),
    description: yup.string().required('Description is required'),
    // previousOwners: yup.string().optional(),
    authenticationDetailsFileId: yup.mixed(),
    factSheetFileId: yup.mixed().notOneOf(['', false, undefined, null], 'Fact Sheet is required'),
    conditionReportId: yup.mixed().notOneOf(['', false, undefined, null], 'Condition Report is required'),
    photoFileId: yup.mixed().notOneOf(['', false, undefined, null], 'Artwork cover is required'),
    selectedFiles: yup.mixed()
})

const AddArtListing = (props: any) => {
    const _artWorkCover: any = useRef()
    const _authDetails: any = useRef()
    const _conditionReport: any = useRef()
    const _factSheet: any = useRef()
    const _otherPhotos: any = useRef()

    const [files, setFiles] = useState([])
    const [isLoading, setLoading] = useState(false)
    const [saveError, setSaveError] = useState('')
    const [openPreview, setOpenPreview] = useState(false)
    const [artworkCover, setArtWorkCover] = useState(undefined)
    const [authenticationDetails, setAuthenticationDetails] = useState(undefined)
    const [conditionReport, setConditionReport] = useState(undefined)
    const [factSheet, setFactSheet] = useState(undefined)
    const [otherImage, setOtherImage] = useState(undefined)
    const [formData, setFormData]: [any, any] = useState({})
    const handleFile = (file: any, setFile: any, key: string) => {
        const reader = new FileReader()
        reader.onload = (e: any) => {
            if (key === 'artWorkCover') {
                setArtWorkCover(e.target.result)
            }
            if (key === 'authenticationDetails') {
                setAuthenticationDetails(e.target.result)
            }
            if (key === 'conditionReport') {
                setConditionReport(e.target.result)
            }
            if (key === 'factSheet') {
                setFactSheet(e.target.result)
            }
            setFile({
                fileName: file.name,
                decodedFile: e?.target?.result?.split(',')[1],
                type: file.type
            })
        }
        reader.readAsDataURL(file)
    }

    const getBase64 = (file: any, cb: any) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            cb(reader.result)
        };
        reader.onerror = (error) => {
            console.log('Error: ', error);
        };
    }

    const convertDateToUnixTime = (date: string) => {
        const dateParts = date.split("-")
        const dateObject = new Date(+dateParts[2], +dateParts[0] - 1, +dateParts[1])
        const dateToConvert = dateObject.toString()

        return Date.parse(dateToConvert)
    }


    const multipleFileSelectedHandler = (e: any, onChange: any) => {
        if (e.target.files) {
            const imgArr: any = []
            const filesArray = Array.from(e.target.files).map((image: any) => {
                getBase64(image, (result: any) => {
                    imgArr.push(result)
                    setFiles([...files,
                    {
                        fileName: image?.name,
                        decodedFile: result.split(',')[1],
                        type: image?.type
                    }])
                })
                return URL.createObjectURL(image)
            })
            setOtherImage(imgArr)
            onChange(filesArray)
        }
    }

    const _onSubmit = (data: any) => {
        const submitPurpose = 'POST'
        const _data = {
            itemAttribute: {
                ...data,
                userId: props.user?.id,
                isPosted: 1,
                numberOfShare: Math.floor(data.listedValue / Number(process.env.VALUE_PER_SHARE)),
                listingDate: Date.parse(new Date(String(data.listingDate)).toString()),
                saleDate: Date.parse(new Date(String(data.saleDate)).toString())
            },
            authenticationDetailsFileId: data?.authenticationDetailsFileId,
            factSheetFileId: data?.factSheetFileId,
            conditionReportId: data?.conditionReportId,
            photoFileId: data?.photoFileId
        }
        setLoading(true)
        setSaveError('')
        props.addItem({ submitPurpose, ..._data, files })
            .then(() => {
                setLoading(false)
                props.router?.navigate('/listing')
                setTimeout(() => props.resetAddItem(), 15000)
            })
            .catch(() => {
                setLoading(false)
                setSaveError('Something went wrong. Please try again')
                setTimeout(() => props.resetAddItem(), 15000)
            })
    }

    const onSaveDraft = (data: any) => {
        const submitPurpose = 'SAVE'
        const _data = {
            itemAttribute: {
                ...data,
                userId: props.user?.id,
                isPosted: 0,
                numberOfShare: Math.floor(data.listedValue / Number(process.env.VALUE_PER_SHARE)),
                listingDate: Date.parse(new Date(String(data.listingDate)).toString()),
                saleDate: Date.parse(new Date(String(data.saleDate)).toString())
            },
            authenticationDetailsFileId: data?.authenticationDetailsFileId,
            factSheetFileId: data?.factSheetFileId,
            conditionReportId: data?.conditionReportId,
            photoFileId: data?.photoFileId
        }
        setLoading(true)
        props.addItem({ submitPurpose, ..._data, files })
            .then(() => {
                setLoading(false)
                props.router?.navigate('/listing')
                setTimeout(() => props.resetAddItem(), 15000)
            })
            .catch(() => {
                setLoading(false)
                setTimeout(() => props.resetAddItem(), 15000)
            })
    }

    const onSetPreview = (data: any) => {
        console.log(artworkCover)
        setFormData({ ...data })
        setOpenPreview(true)
    }

    // const { Form, instance, onSubmit } = ArtForm({ onSubmit: (values, _instance) => { console.log(values, _instance) } })
    const { Form, onSubmit, control, handleSubmit } = ArtForm({ formSchema: FormSchema, onSubmit: _onSubmit, otherOptions: { mode: 'onSubmit', criteriaMode: 'all' } })
    const _state = props?.router?.location?.state
    return (
        // <ArtForm renderFormChild={renderChild} onSubmit={(values, instance) => {console.log('wew')}} />
        <React.Fragment>
            <ArtHeader />
            <Form id='AddListingForm' className='addListingForm' onSubmit={onSubmit}>
                {props.isError && <Alert variant='danger mx-5 mt-2'><FormattedMessage id='AddArtListing.error.addListing' /></Alert>}
                {/* <img loading='lazy' src={artworkCover} width={200} height={200} /> */}
                <div className="d-flex justify-content-between mt-5">
                    <h1><FormattedMessage id='AddArtListing.title' /></h1>
                    {openPreview && !_.isEmpty(formData) && <Navigate to='/listing/preview' state={{ ...formData, factSheet: factSheet ?? _state?.factSheet, authenticationDetails: authenticationDetails ?? _state?.authenticationDetails, conditionReport: conditionReport ?? _state?.conditionReport, artworkCover: artworkCover ?? _state?.artworkCover, otherImage: otherImage ?? (_state?.otherImage || []), files: files?.length > 0 ? files : _state?.files }} />}
                    <button onClick={handleSubmit(onSetPreview)} type='button' className='am-upload__button preview-btn'><FormattedMessage id='AddArtListing.label.preview' /></button>
                </div>
                <div className='d-flex flex-row justify-content-around'>
                    <div className='d-flex flex-column gap-4'>
                        <ArtField required id='title' defaultValue={_state?.title} name='title' type='text' label='Artwork Title' fieldstype={ArtFieldsType.ArtInput} control={control} />
                        <ArtField id='artist' defaultValue={_state?.artist} name='artist' type='text' label='Artist' fieldstype={ArtFieldsType.ArtInput} control={control} />
                        <ArtField id='listedValue' defaultValue={_state?.listedValue} name='listedValue' type='number' label='Value' fieldstype={ArtFieldsType.ArtInput} control={control} />
                        <ArtField id='dmt' name='dmt' defaultValue={_state?.dmt} type='text' label='Dimension/Material/Technique' fieldstype={ArtFieldsType.ArtInput} control={control} />
                        <ArtField id='description' defaultValue={_state?.description} name='description' type='textarea' label='Description' className='am-textarea' fieldstype={ArtFieldsType.ArtTextArea} control={control} />
                    </div>
                    <div className='d-flex flex-column gap-4'>
                        <ArtField id='year' defaultValue={_state?.year} name='year' type='number' label='Year' fieldstype={ArtFieldsType.ArtInput} control={control} />
                        <ArtField id='displayLocation' defaultValue={_state?.displayLocation} name='displayLocation' type='text' label='Display Location' fieldstype={ArtFieldsType.ArtInput} control={control} />
                        {/* <ArtField id='listingDate' defaultValue={_state?.listingDate} name='listingDate' type='date' label='Listing Date' fieldstype={ArtFieldsType.ArtInput} control={control} /> */}
                        <ArtField id='listingDate' defaultValue={_state?.listingDate} name='listingDate' fieldstype={ArtFieldsType.CustomField} control={control} renderField={(onChange: any, value: any, error?: any) => (
                            <div>
                                <label><FormattedMessage id='AddArtListing.label.listingDate' /></label>
                                <ArtDatePicker placement='top-start' onChange={onChange} advanceYear={5} showCalendarIcon placeholder='(MM/DD/YYYY)' inputClassName='w-100' className={classnames(!!error && 'border border-danger rounded', 'w-100')} />
                            </div>
                        )} />
                        <ArtField id='saleDate' defaultValue={_state?.saleDate} name='saleDate' fieldstype={ArtFieldsType.CustomField} control={control} renderField={(onChange: any, value: any, error?: any) => (
                            <div>
                                <label><FormattedMessage id='AddArtListing.label.saleDate' /></label>
                                <ArtDatePicker placement='top-start' onChange={onChange} advanceYear={5} showCalendarIcon placeholder='(MM/DD/YYYY)' inputClassName='w-100' className={classnames(!!error && 'border border-danger rounded', 'w-100')} />
                            </div>
                        )} />
                        {/* <ArtField id='saleDate' defaultValue={_state?.saleDate} name='saleDate' type='date' label='Sale Date' fieldstype={ArtFieldsType.ArtInput} control={control} /> */}
                    </div>
                </div>
                <h1><FormattedMessage id='AddArtListing.label.attachement' /></h1>
                <div className='d-flex flex-row justify-content-around'>
                    <div className="d-flex flex-column gap-4">
                        <ArtField defaultValue={_state?.photoFileId} control={control} id='photoFileId' name='photoFileId' fieldstype={ArtFieldsType.CustomField} renderField={(onChange: any, value: any, error?: any) => (
                            <div>
                                <div>
                                    <label><FormattedMessage id='AddArtListing.label.artworkCover' /></label>
                                    <div className={classnames('am-upload', !!error && 'border border-danger')}>
                                        <button type='button' className='am-upload__button' onClick={() => _artWorkCover?.current?.click()}><FormattedMessage id='AddArtListing.label.chooseFile' /> <img loading='lazy' src='/Icons/clip-icon.png' /></button>
                                        {(artworkCover || _state?.artworkCover) && <span className='border border-success ml-2 p-1 rounded-circle'><i className='fa fa-check text-success' /></span>}
                                        <input type='file' className='d-none' ref={_artWorkCover} onChange={(e) => handleFile(e?.target?.files![0], onChange, 'artWorkCover')} />
                                        <ArtField defaultValue={_state?.selectedFiles} control={control} id='selectedFiles' name='selectedFiles' fieldstype={ArtFieldsType.CustomField} renderField={(_onChange: any) => (
                                            <>
                                                <input type='file' multiple className='d-none' ref={_otherPhotos} onChange={e => multipleFileSelectedHandler(e, _onChange)} />
                                                <button type='button' className='am-upload__button am-upload__button--link' onClick={() => _otherPhotos?.current?.click()}><FormattedMessage id='AddArtListing.label.attachOtherPhotos' /></button>
                                                {((files && files.length > 0) || (_state?.files && _state?.files.length > 0)) && <span className='border border-success ml-2 p-1 rounded-circle'><i className='fa fa-check text-success' /></span>}
                                            </>
                                        )} />
                                    </div>
                                </div>
                            </div>
                        )} />

                        <div>
                            <label><FormattedMessage id='AddArtListing.label.authenticationDetails' /></label>
                            <ArtField defaultValue={_state?.authenticationDetailsFileId} control={control} id='authenticationDetailsFileId' name='authenticationDetailsFileId' fieldstype={ArtFieldsType.CustomField} renderField={(onChange: any, value: any, error?: any) => (
                                <div className="am-upload">
                                    <button type='button' className='am-upload__button' onClick={() => _authDetails?.current?.click()}><FormattedMessage id='AddArtListing.label.chooseFile' /> <img loading='lazy' src='/Icons/clip-icon.png' /></button>
                                    {(authenticationDetails || _state?.authenticationDetails) && <span className='border border-success ml-2 p-1 rounded-circle'><i className='fa fa-check text-success' /></span>}
                                    <input type='file' className='d-none' ref={_authDetails} onChange={(e) => handleFile(e?.target?.files![0], onChange, 'authenticationDetails')} />
                                </div>
                            )} />
                        </div>
                    </div>
                    <div className="d-flex flex-column gap-4">
                        <div>
                            <label><FormattedMessage id='AddArtListing.label.conditionReport' /></label>
                            <ArtField defaultValue={_state?.conditionReportId} control={control} id='conditionReportId' name='conditionReportId' fieldstype={ArtFieldsType.CustomField} renderField={(onChange: any, value: any, error?: any) => (
                                <div className={classnames('am-upload', !!error && 'border border-danger')}>
                                    <button type='button' className='am-upload__button' onClick={() => _conditionReport?.current?.click()}><FormattedMessage id='AddArtListing.label.chooseFile' /> <img loading='lazy' src='/Icons/clip-icon.png' /></button>
                                    {(conditionReport || _state?.conditionReport) && <span className='border border-success ml-2 p-1 rounded-circle'><i className='fa fa-check text-success' /></span>}
                                    <input type='file' className='d-none' ref={_conditionReport} onChange={(e) => handleFile(e?.target?.files![0], onChange, 'conditionReport')} />
                                </div>
                            )} />
                        </div>
                        <div>
                            <label><FormattedMessage id='AddArtListing.label.factSheet' /></label>
                            <ArtField defaultValue={_state?.factSheetFileId} control={control} id='factSheetFileId' name='factSheetFileId' fieldstype={ArtFieldsType.CustomField} renderField={(onChange: any, value: any, error?: any) => (
                                <div className={classnames('am-upload', !!error && 'border border-danger')}>
                                    <button type='button' className='am-upload__button' onClick={() => _factSheet?.current?.click()}><FormattedMessage id='AddArtListing.label.chooseFile' /> <img loading='lazy' src='/Icons/clip-icon.png' /></button>
                                    {(factSheet || _state?.factSheet) && <span className='border border-success ml-2 p-1 rounded-circle'><i className='fa fa-check text-success' /></span>}
                                    <input type='file' className='d-none' ref={_factSheet} onChange={(e) => handleFile(e?.target?.files![0], onChange, 'factSheet')} />
                                </div>
                            )} />
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-row justify-content-end am-submit__container">
                    <button disabled={isLoading} onClick={handleSubmit(onSaveDraft)} type='submit' className='am-upload__button am-upload__button--link'>{isLoading ? <Spinner animation='border' size='sm' /> : <FormattedMessage id='AddArtListing.button.saveAsDraft' />}</button>
                    <button disabled={isLoading} type='button' onClick={() => props.router?.navigate('/listing')} className='am-upload__button'><FormattedMessage id='AddArtListing.button.cancel' /></button>
                    <button disabled={isLoading} onClick={onSubmit} type='submit' className='am-upload__button am-upload__button--submit'>{isLoading ? <Spinner animation='border' size='sm' /> : <FormattedMessage id='AddArtListing.button.ok' />}</button>
                </div>
            </Form>
        </React.Fragment>

        // <React.Fragment>
        //     <div className="header"><img loading='lazy' src='/am-logo3.png' /></div>
        //     <form className='addListingForm'>

        //     </form>
        // </React.Fragment>
    )
}

const mapStateToProps = (state: ReduxAppState) => {
    return {
        user: state.api?.user?.authUser,
        itemList: state.api?.items?.getItemList,
        // errorMsg: state.api?.user?.authUser?.error,
        // isSignedIn: state.api?.user?.authUser?.isSignedIn,
        // isLoading: state.api?.user.authUser.loading,
        // isError: state.api?.user.authUser.statusText === 'error',
        isError: state.api?.items?.addItem.statusText === 'error'
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    addItem: (data: any) => addItem(dispatch, data),
    resetAddItem: () => dispatch({ type: RESET_ADD_ITEM })
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(compose(SidebarHOC)(AddArtListing as any)))