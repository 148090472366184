import authUserReducer, { authUserInitialState, AuthUserInfoState } from './user'
import getUserListReducer, { getUserListInitialState, GetUserListState } from './getUsers'
import getUserInfoReducer, { getUserInfoInitialState, GetUserInfoState } from './getUserInfo'
import getPortfolioReducer, { getPortfolioInitialState, GetPortfolioState } from './getPortfolio'
import updateUserStatusReducer, { updateUserStatusInitialState, UpdateUserStatusState } from './updateUserStatus'

import { combineReducers } from 'redux'

export interface User {
    [a: string]: any
    id: string
    cognitoId: string,
    email: string
    name: string,
    country: string,
    ccy: string,
    dateOfBirth: string,
    gender: string,
    language: string,
    lastname: string,
    mobileNo: string,
    nationality: string,
    nickname: string,
    residence: string,
    profilePicId: string,
    profilePicUrl: string,
    profilePicThumbnail: string,
    timeZone: string,
    bio: string,
    userType: number,
    isPublic: boolean,
    street: string,
    barangay: string,
    cityMunicipality: string,
    balance: number
}

export const acctInfoInitialState: AccountInfoInitialState = {
    authUser: authUserInitialState,
    getUserList: getUserListInitialState,
    getUserInfo: getUserInfoInitialState,
    getPortfolio: getPortfolioInitialState,
    updateUserStatus: updateUserStatusInitialState,
}

export interface AccountInfoInitialState {
    authUser: AuthUserInfoState,
    getUserList: GetUserListState,
    getUserInfo: GetUserInfoState,
    getPortfolio: GetPortfolioState,
    updateUserStatus: UpdateUserStatusState
}

const userReducer = combineReducers({
    authUser: authUserReducer,
    getUserList: getUserListReducer,
    getUserInfo: getUserInfoReducer,
    getPortfolio: getPortfolioReducer,
    updateUserStatus: updateUserStatusReducer
})

export default userReducer