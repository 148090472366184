import React from 'react'

import { compose, Dispatch } from 'redux'
import { connect } from 'react-redux'
import { logOutUser } from '../../reducers/User/user'
import { ReduxAppState } from '../../utils/types'
import { withRouter } from '../../hoc/withRouter'
import { User } from '../../reducers/User'
import { getUserList } from '../../reducers/User/getUsers'
import { FormattedMessage } from 'react-intl'
import { generateRandomId } from '../../utils'
import { getUserInfo } from '../../reducers/User/getUserInfo'
import Sidebar from '../../hoc/sidebar'
import { Alert, Modal, Spinner, Table } from 'react-bootstrap'
import * as _ from 'lodash'
import classNames from 'classnames'
import { ArtHeader } from '../../ReusableComponent'

export interface UsersPageProps {
    router: any
}

export interface StateProps {
    currentUser?: User
    userList?: any
    isError: boolean
}

export interface DispatchProps {
    logOutUser: () => any,
    getUserList: (query?: any) => any,
    getUserInfo: (id: string) => any
}

export interface HomePageState {
    selectedTooltip: string
    searchValue: string
}

export type UsersProps = UsersPageProps & StateProps & DispatchProps

class UsersPage extends React.Component<UsersProps, HomePageState> {
    searchRef: React.RefObject<any>
    constructor(props: UsersProps) {
        super(props)
        this.state = {
            selectedTooltip: '',
            searchValue: ''
        }

        this.searchRef = React.createRef()
    }

    componentDidMount(): void {
        this.props.getUserList({ filter: 'userType==1' })
    }

    onLogoutPressed = () => {
        this.props.logOutUser()
    }

    onGetUserDetails = (id: string) => {
        this.props.router.navigate(`/user/${id}`)
    }

    onSelectToolTip = (userId: string) => {
        this.setState({
            selectedTooltip: userId
        })
    }

    onChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            searchValue: e.target.value
        }, () => this.onSearchArtwork())
    }

    onSearchArtwork = () => {
        this.props.getUserList(this.state.searchValue ? { filter: `userType==1&email>@${this.state.searchValue}` } : { filter: 'userType==1' })
    }

    onDeleteSearch = () => {
        this.searchRef.current.value = ''
        if (this.state.searchValue) this.setState({ searchValue: '' }, () => this.onSearchArtwork())
    }

    getStatus = (status: number) => {
        if (status === 2) {
            return 'Dormant'
        } else if (status === 1) {
            return 'Blocked'
        } else {
            return 'Active'
        }
    }

    render() {
        const _users = this.props.userList?.response ?? []
        return (
            <React.Fragment>
                <ArtHeader />
                <div className='d-flex justify-content-between align-items-center mb-3 mt-5 mx-5'>
                    <p className='title'><FormattedMessage id='Users.label.accountListing' /></p>
                    <div className="d-flex gap-5">
                        <div className='position-relative'>
                            <input
                                className={'search-field rounded'}
                                type={'text'}
                                onChange={_.debounce(this.onChangeSearch, 500)}
                                ref={this.searchRef}
                            />
                            <span onClick={this.onDeleteSearch} className='search-icon'><i className={classNames('fa', this.state.searchValue ? 'fa-times' : 'fa-search')}></i></span>
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-row mx-5 table-container">
                    <Table className="amtable">
                        <thead>
                            <tr>
                                <th className="column" style={{ width: '20%' }}><FormattedMessage id='Users.label.status' /></th>
                                <th className="column" style={{ width: '30%' }}><FormattedMessage id='Users.label.name' /></th>
                                <th className="column" style={{ width: '30%' }}><FormattedMessage id='Users.label.email' /></th>
                                <th className='column' style={{ width: '10%' }} />
                            </tr>
                        </thead>
                        <tbody style={{ position: 'relative', height: this.props.userList?.loading && '5rem' }}>
                            {this.props.userList?.loading ? (
                                <Spinner animation='border' size='sm' className='am-spinner' />
                            ) : _users && _users.length > 0 ? _users.map((user: User) => (
                                <tr
                                    id={user?.id}
                                    key={generateRandomId()}
                                >
                                    <td>{this.getStatus(user?.status)}</td>
                                    <td>{user?.name}</td>
                                    <td>{user?.email}</td>
                                    <td>
                                        <span onClick={() => this.onGetUserDetails(user?.id)} style={{ cursor: 'pointer' }}><img loading='lazy' src='/Icons/expand-icon.png' width={15} height={15} className="mt-1" /></span>
                                    </td>
                                </tr>
                            )) : <div><FormattedMessage id='Users.label.noDataFound' /></div>}
                        </tbody>
                    </Table>
                </div>
            </React.Fragment >
        )
    }
}

const mapStateToProps = (state: ReduxAppState) => {
    const _getUserList = state.api?.user?.getUserList
    return {
        currentUser: state.api?.user?.authUser?.response,
        userList: state.api?.user?.getUserList,
        isError: _getUserList.statusText === 'error',
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    logOutUser: () => logOutUser(dispatch),
    getUserList: (query?: any) => getUserList(dispatch, query)
})


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(compose(Sidebar)(UsersPage as any)))
